import dayjs, { Dayjs } from 'dayjs';

import {
  ALL_PERMISSIONS,
  INVALID_NUMERIC_CHARACTERS,
} from '@src/constants/common';
import { useCheckPermission } from '@src/hooks/useCheckPermission';

//  isEmpty -> Checks if the value is an empty object, empty array or empty string.
export const isEmpty = (value: any): boolean => {
  if (typeof value === 'number') {
    value = value.toString();
  }
  if (!value) return true;
  if (Array.isArray(value) || typeof value === 'string') return !value.length;
  if (typeof value === 'object') return !Object.keys(value).length;
  return true;
};

// pick -> used to return a copy of the object that is composed of the picked object properties.
export const pick = (
  obj: Record<string, unknown>,
  paths: string | string[],
): Record<string, unknown> => {
  if (
    isEmpty(obj) ||
    (Array.isArray(paths) && !paths.length) ||
    (typeof paths === 'string' && !paths)
  ) {
    return {};
  }
  let pathList;
  if (typeof paths === 'string') {
    pathList = [paths];
  } else {
    pathList = [...paths];
  }
  const values = Object.keys(obj)
    ?.filter(key => pathList.includes(key))
    ?.reduce(
      (accumulator, key) => ({
        ...accumulator,
        [key]: obj[key],
      }),
      {},
    );
  return values;
};

// isEqual -> function to compare 2 values (accepts -> arrays, objects, numbers, strings)
export const isEqual = (value1, value2): boolean => {
  if (value1 === value2) {
    return true;
  }

  // to compare 2 objects/arrays
  if (typeof value1 == 'object' && typeof value2 == 'object') {
    if (Object.keys(value1).length === 0 && Object.keys(value2).length === 0)
      return true;

    if (Object.keys(value1).length !== Object.keys(value2).length) return false;
    return Object.keys(value1)?.every(key => isEqual(value1[key], value2[key]));
  }

  return false;
};

export const pushDataToDataLayer = (data: Record<string, unknown>): void => {
  if (window && data) {
    window.dataLayer?.push({ ...data });
  }
};

export const removeInvalidNumericCharactersOnInput = (
  e,
  invalidCharacters = INVALID_NUMERIC_CHARACTERS,
): boolean => {
  if (invalidCharacters.includes(e.key)) {
    e.preventDefault?.();
    return false;
  }

  return true;
};

export const displayValueOrPlaceholder = (
  value: number | string,
  emptyValuePlaceholder = 'NA',
): string | number => {
  if (!value) return emptyValuePlaceholder;

  return value;
};

// function to debounce functions based on timeout in milli-seconds
export const debounce = (func: any, timeout = 300) => {
  let timer: NodeJS.Timeout;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

// function to convert date-time to WIB time zone irrespective of user's current timezone
export const convertToWIB = (
  dateTime: string | Date | number | Dayjs,
): string => {
  if (!dateTime) return '';
  const inputDate = dayjs.isDayjs(dateTime) ? dateTime : dayjs(dateTime);

  // here 420 is 7 * 60, WIB time zone considered is +07:00
  return inputDate
    .utcOffset(420, true) // Adjust to WIB (+07:00) and keep time local to the specified offset
    .toISOString();
};

export const startCase = (value: string) => {
  if (!value) return null;
  if (typeof value === 'string') {
    return value.charAt(0) + value.toLocaleLowerCase().slice(1);
  }
  return value;
};

//Function to get a tab Array based on permissions assigned to a user to a user
export const PermissionBasedTabArray = (
  allTabsObject: Record<
    string,
    {
      id: string | number;
      label: string;
      content: JSX.Element;
    }
  >,

  currentTabArray: {
    id: string | number;
    label: string;
    content: JSX.Element;
    value: number;
  }[],
) => {
  const { hasAccessByPermissionKeys } = useCheckPermission();
  Object.keys(allTabsObject).forEach(tabName => {
    if (hasAccessByPermissionKeys([ALL_PERMISSIONS[tabName]])) {
      const newArrayLength = currentTabArray.length;
      currentTabArray.push({
        ...allTabsObject[tabName],
        value: newArrayLength,
      });
    }
  });
};

export const hasEmptyOrNullValue = object => {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      if (object[key] === null || object[key] === '') {
        return true;
      }
    }
  }
  return false;
};
